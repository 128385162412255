import React, { useState, useEffect } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import appLogo from '../../assets/appbar/app-logo.svg';
import appLogos from '../../assets/appbar/Logo white.png';
import Container from '@material-ui/core/Container';
import Link from '@material-ui/core/Link';
import LoginView from './LoginView';
import RegistrationView from './RegistrationView';
import ForgotPassword from './ForgotPassword';
import UnblockUser from './UnblockUser';
import Hidden from '@material-ui/core/Hidden';
import Authenticator from './Authenticator';

function Copyright() {
  return (
    <Typography variant='body2' color='textSecondary'>
      {'Copyright © '}
      <Link color='inherit' href='https://material-ui.com/'>
        DigitX. All rights reserved.
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}
const useStyles = makeStyles((theme) => ({
  root: {
    height: '93vh',
  },
  image: {
    backgroundColor: '#1c6dff',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  paper: {
    margin: theme.spacing(7, 5),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  imgContainer: {
    margin: theme.spacing(7, 5),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  footer: {
    padding: theme.spacing(3, 2),
    marginTop: 'auto',
    backgroundColor:
      theme.palette.type === 'light'
        ? theme.palette.grey[200]
        : theme.palette.grey[800],
  },
  img: {
    position: 'absolute',
    top: '15rem',
    width: '35rem',
    height: '11rem',
    left: '8rem',
  },
}));

function Auth({ referralLink }) {
  const classes = useStyles();
  const [currentStep, setCurrentStep] = useState('LOGIN');
  const AllViews = {
    LOGIN: <LoginView setCurrentStep={setCurrentStep} />,
    REGISTRATION: (
      <RegistrationView
        setCurrentStep={setCurrentStep}
        referralLink={referralLink}
      />
    ),
    FORGOT_PASSWORD: <ForgotPassword setCurrentStep={setCurrentStep} />,
    UNBLOCK_USER: <UnblockUser setCurrentStep={setCurrentStep} />,
    '2FA_AUTHENTICATOR': (
      <Authenticator
        setCurrentStep={setCurrentStep}
        title={'Enter G-Code'}
        label={'Authenticator code'}
      />
    ),
    OTP_AUTHENTICATOR: (
      <Authenticator
        setCurrentStep={setCurrentStep}
        title={'ENTER OTP'}
        label={'Otp code'}
      />
    ),
  };

  useEffect(() => {
    if (referralLink != null) {
      setCurrentStep('REGISTRATION');
    }
  }, [referralLink]);

  return (
    <>
      <Grid container component='main' className={`${classes.root}`}>
        <CssBaseline />
        <Grid item xs={false} sm={false} md={8} className={classes.image}>
          <Hidden only={['xs', 'sm']}>
            <img src={appLogos} alt='app-logo' className={classes.img} />
          </Hidden>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={4}
          component={Paper}
          elevation={0}
          square
        >
          <div className={classes.imgContainer}>
            <img src={appLogo} alt='app-logo' />
            <br />
            {AllViews[currentStep]}
          </div>
        </Grid>
      </Grid>
      <footer className={`App ${classes.footer}`}>
        <Container maxWidth={false}>
          <Copyright />
        </Container>
      </footer>
    </>
  );
}

export default Auth;
