import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import thunk from 'redux-thunk';

import theme from './reducers/theme';
import orders from './reducers/orders';
import marketwatch from './reducers/marketwatch';
import funds from './reducers/funds';
import modals from './reducers/modals';
import profile from './reducers/profile';
import updates from './reducers/updates';
import kyc from './reducers/kyc';
import formValidations from './reducers/formValidations';
import loginCred from './reducers/loginCred';

const composeEnhancers =
  (process.env.NODE_ENV === 'development' &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
  compose;

const rootReducer = combineReducers({
  theme,
  orders,
  marketwatch,
  funds,
  modals,
  profile,
  updates,
  kyc,
  formValidations,
  loginCred,
});

const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(thunk))
);

export default store;
