import React from 'react';
import TextField from '@material-ui/core/TextField';

function Form({
  error,
  variant,
  classes,
  format,
  type,
  label,
  name,
  defaultValue,
  value,
  handleChangeValue,
  helperText,
  isDisabled = false,
}) {
  return (
    <TextField
      disabled={isDisabled}
      error={error}
      variant={variant}
      fullWidth
      className={classes.inputBase}
      format={format}
      type={type}
      label={label}
      name={name}
      defaultValue={defaultValue}
      value={value}
      onChange={handleChangeValue}
      helperText={error ? helperText : ''}
    />
  );
}

export default Form;
