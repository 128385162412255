import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { loginAPI } from '../../services/backendAPI';
import { useSnackbar } from 'notistack';
import Backdrop from '@material-ui/core/Backdrop';

import Loader from '../../Main/Loader';
import useForm from '../../hooks/useForm';

import Form from '../Common/Forms';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles((theme) => ({
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  paper: {
    margin: theme.spacing(7, 5),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    textTransform: 'capitalize',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

function LoginView({ setCurrentStep }) {
  const dispatch = useDispatch();
  const [values, handleChangeValue, handleChangeForTheFirstMount] = useForm();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);

  const login = (loginReqPayload) => {
    setLoading(true);

    loginAPI(loginReqPayload)
      .then(({ data, message }) => {
        dispatch({
          type: 'GET_LOGIN_CREDIATIALS',
          payload: loginReqPayload,
        });
        let date = new Date().toLocaleString();
        if (data.twofa_enabled && !data.auth_token) {
          enqueueSnackbar(
            'otp sent to your registered mobile number and email id',
            {
              variant: 'success',
            }
          );
          localStorage.setItem('login_id', data.client_id);
          localStorage.setItem('email_id', data.email_id);
          localStorage.setItem('twofa_token', data.twofa_token);
          localStorage.setItem('twofa_enabled', data.twofa_enabled);
          localStorage.setItem('time', date);
          localStorage.setItem('kyc_status', data.kycStatus);
          localStorage.setItem('curr_kyc_status', data.kycStatus);
          localStorage.setItem('kyc_submitted', data.kycSubmitted);
          setLoading(false);
          setCurrentStep('2FA_AUTHENTICATOR');
        } else {
          enqueueSnackbar(
            'otp sent to your registered mobile number and email id',
            {
              variant: 'success',
            }
          );
          localStorage.setItem('login_id', data.client_id);
          localStorage.setItem('email_id', data.email_id);
          localStorage.setItem('twofa_token', data.twofa_token);
          localStorage.setItem('twofa_enabled', data.twofa_enabled);
          localStorage.setItem('time', date);
          localStorage.setItem('kyc_status', data.kycStatus);
          localStorage.setItem('curr_kyc_status', data.kycStatus);
          localStorage.setItem('kyc_submitted', data.kycSubmitted);
          setLoading(false);
          setCurrentStep('OTP_AUTHENTICATOR');
        }
      })
      .catch((err) => {
        enqueueSnackbar(err, { variant: 'error' });
        setLoading(false);
        localStorage.clear();
      });
  };

  const classes = useStyles();
  return (
    <>
      <Backdrop className={classes.backdrop} open={loading}>
        <Loader />
      </Backdrop>
      <Typography component='h1' variant='h5'>
        Sign in
      </Typography>
      <form className={classes.form} noValidate>
        <Form
          variant='standard'
          classes={classes}
          label='Username'
          // label='User Name / Email Id'
          name='login_id'
          value={values.login_id || ''}
          handleChangeValue={(e) => handleChangeValue(e, false)}
        />
        <br />
        <br />
        <Form
          variant='standard'
          classes={classes}
          type='password'
          label='Password'
          name='password'
          value={values.password || ''}
          handleChangeValue={(e) => handleChangeValue(e, false)}
        />
        <br />
        <br />
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Typography
              gutterBottom
              variant='subtitle1'
              align='right'
              onClick={() => setCurrentStep('UNBLOCK_USER')}
              style={{ cursor: 'pointer', textAlign: 'left' }}
            >
              Unblock User?
            </Typography>
          </Grid>
          <Grid itemx xs={12} md={6} align='right'>
            <Typography
              gutterBottom
              variant='subtitle1'
              align='right'
              onClick={() => setCurrentStep('FORGOT_PASSWORD')}
              style={{ cursor: 'pointer', textAlign: 'right' }}
            >
              Forgot Password?
            </Typography>
          </Grid>
          <Grid item xs={12} md={6} align='left'>
            <Button
              type='submit'
              fullWidth
              size='large'
              variant='contained'
              color='primary'
              autoFocus
              onClick={(e) => {
                e.preventDefault();
                login(values);
                window.localStorage.setItem('theme', 'dark');
              }}
              className={`select-text ${classes.submit}`}
            >
              Sign In
            </Button>
          </Grid>
          <Grid item xs={12} md={6} align='right'>
            <Button
              type='submit'
              fullWidth
              size='large'
              variant='contained'
              color='primary'
              className={`select-text ${classes.submit}`}
              onClick={() => setCurrentStep('REGISTRATION')}
            >
              Sign up
            </Button>
          </Grid>
        </Grid>
      </form>
    </>
  );
}

export default LoginView;
