const initialState = {
  favourites: [],
  contractList: [],
  activeContract: {},
  activeSymbolName: '',
  currDepth: {},
  baseExchanges: [],
  symbolsList: [],
  arrPricesBySymbol: [],
  watchlistIndex: 0,
  priceQtyFromDepthForBuy: {
    qty: 0,
    price: 0,
  },
  priceQtyFromDepthForSell: {
    qty: 0,
    price: 0,
  },
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case 'SET_FAVOURITES':
      return {
        ...state,
        favourites: action.payload,
      };
    case 'SET_CONTRACT_DETAILS':
      return {
        ...state,
        contractList: action.payload,
      };
    case 'SET_ACTIVE_CONTRACT':
      return {
        ...state,
        activeContract: action.payload,
      };
    case 'SET_ACTIVE_SYMBOL_NAME':
      return {
        ...state,
        activeSymbolName: action.payload,
      };
    case 'SET_CURR_DEPTH':
      return {
        ...state,
        currDepth: action.payload,
      };
    case 'SET_BASE_EXCHANGES':
      return {
        ...state,
        baseExchanges: action.payload,
      };
    case 'SET_SYMBOLS_LIST':
      return {
        ...state,
        symbolsList: action.payload,
      };
    case 'SET_SYMBOLS_LIST_PRICES':
      return {
        ...state,
        arrPricesBySymbol: action.payload,
      };
    case 'SET_WATCHLIST_INDEX':
      return {
        ...state,
        watchlistIndex: action.payload,
      };
    case 'SET_PRICE_QTY_FROM_DEPTH_FOR_BUY':
      return {
        ...state,
        priceQtyFromDepthForBuy: action.payload,
      };
    case 'SET_PRICE_QTY_FROM_DEPTH_FOR_SELL':
      return {
        ...state,
        priceQtyFromDepthForSell: action.payload,
      };
    default:
      return state;
  }
}
