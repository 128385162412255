import {
  fetchKycRegions,
  fetchKycDetails,
  uploadImg,
  submitKyc,
  getBankDetails,
  getKycInfo,
  sendOcrReqForPan,
  sendOcrReqForAadhaar,
} from '../../services/backendAPI';
import alertify from 'alertifyjs';

export const getKycInfoAction = () => (dispatch) => {
  getKycInfo()
    .then(({ status, data }) => {
      if (status === 'success') {
        dispatch({
          type: 'SET_KYC_INFO',
          payload: data,
        });
      }
    })
    .catch((err) => alertify.error(err));
};

export const fetchKycRegionAndDetails = () => (dispatch) => {
  fetchKycRegions()
    .then(({ status, data }) => {
      if (status === 'success') {
        dispatch({
          type: 'SET_KYC_REGION',
          payload: data.selected_region,
        });
        fetchKycDetails(data.selected_region.region_id)
          .then(({ data }) => {
            dispatch({
              type: 'GET_KYC_DETAILS',
              payload: data,
            });
            dispatch({
              type: 'SET_REQ_DOCS',
              payload: data.required_docs,
            });
          })
          .catch((err) => alertify.error(err));
      }
    })
    .catch((err) => alertify.error(err));
};

export const postImgForVerificationActionv2 = (
  proofType,
  file,
  docId,
  setDocId,
  setFile
) => {
  let fd = new FormData();
  fd.append('file', file);
  fd.append('client_id', localStorage.getItem('login_id'));
  uploadImg(fd)
    .then(({ data }) => {
      setDocId({ ...docId, doc_id: data.id });
      if (proofType === 'aadhaar') {
        sendOcrReqForAadhaar(data.url)
          .then(({ status, data, message }) => {
            if (status === 'success') {
              alertify.success(message);
            } else {
              setFile('');
              alertify.error(message);
            }
          })
          .catch((err) => {
            setFile('');
            alertify.error(err);
          });
      } else if (proofType === 'pan') {
        sendOcrReqForPan(data.url)
          .then(({ status, data, message }) => {
            if (status === 'success') {
              alertify.success(message);
            } else {
              setFile('');
              alertify.error(message);
            }
          })
          .catch((err) => {
            setFile('');
            alertify.error(err);
          });
      }
    })
    .catch((err) => {
      setFile('');
      alertify.error(err);
    });
};

export const postImgForVerificationAction = (
  proofType,
  file,
  docId,
  setDocId,
  setFile,
  setCurrentStep,
  setLoading,
  setIsSkipAllowed,
  setBtnText1,
  btnText1
) => {
  setLoading(true);
  let fd = new FormData();
  console.log('kyc1', proofType);
  fd.append('file', file);
  fd.append('client_id', localStorage.getItem('login_id'));
  uploadImg(fd)
    .then(({ data }) => {
      setDocId({ ...docId, doc_id: data.id });

      if (proofType === 'aadhaar') {
        sendOcrReqForAadhaar(data.url)
          .then(({ status, data, message }) => {
            if (status === 'success') {
              alertify.success(message);
            } else {
              setFile('');
              alertify.error(message);
            }
          })
          .catch((err) => {
            setFile('');
            alertify.error(err);
          });
      } else if (proofType === 'pan') {
        sendOcrReqForPan(data.url)
          .then(({ status, data, message }) => {
            if (status === 'success') {
              alertify.success(message);
            } else {
              setFile('');
              alertify.error(message);
            }
          })
          .catch((err) => {
            setFile('');
            alertify.error(err);
          });
      }

      setLoading(false);
    })
    .catch((err) => {
      localStorage.setItem('kyc_status', 'VERIFY_UPLOAD');
      setCurrentStep('VERIFY_UPLOAD');
      setFile('');
      setLoading(false);
      setIsSkipAllowed(true);
      setBtnText1({ ...btnText1, btnText_1: 'Upload', btnText_2: 'Skip Now' });
      alertify.error(err);
    });
};

export const submitKycApiCallActionv2 =
  (requiredDocs, region_id, closeModal) => (dispatch) => {
    submitKyc(requiredDocs, region_id)
      .then(({ status, data }) => {
        if (status === 'success') {
          dispatch({
            type: 'SET_KYC_DETAILS',
            payload: data,
          });
          localStorage.setItem('curr_kyc_status', data.kyc_status);
          closeModal();
        } else {
          alertify.error('Something went wrong !');
          closeModal();
        }
      })
      .catch((err) => {
        alertify.error(err);
        closeModal();
      });
  };

export const submitKycApiCallAction =
  (
    requiredDocs,
    region_id,
    setCurrentStep,
    setLoading,
    setIsSkipAllowed,
    setBtnText1,
    btnText1
  ) =>
  (dispatch) => {
    submitKyc(requiredDocs, region_id)
      .then(({ status, data }) => {
        setLoading(true);
        if (status === 'success') {
          localStorage.setItem('kyc_status', data.kyc_status);
          localStorage.setItem('curr_kyc_status', data.kyc_status);
          dispatch({
            type: 'SET_KYC_DETAILS',
            payload: data,
          });
          setLoading(false);
          window.location.reload();
        } else {
          localStorage.setItem('kyc_status', 'VERIFY_UPLOAD');
          setCurrentStep('VERIFY_UPLOAD');
          setLoading(false);
          setIsSkipAllowed(true);
          setBtnText1({
            ...btnText1,
            btnText_1: 'Upload',
            btnText_2: 'Skip Now',
          });
        }
      })
      .catch((err) => {
        localStorage.setItem('kyc_status', 'VERIFY_UPLOAD');
        setCurrentStep('VERIFY_UPLOAD');
        setLoading(false);
        setIsSkipAllowed(true);
        setBtnText1({
          ...btnText1,
          btnText_1: 'Upload',
          btnText_2: 'Skip Now',
        });
        alertify.error(err);
      });
  };

export const getBankDetailsAction = () => (dispatch) => {
  getBankDetails()
    .then(({ data }) =>
      dispatch({
        type: 'SET_BANK_DETAILS',
        payload: data,
      })
    )
    .catch((err) => alertify.error(err));
};
