import axios from 'axios';

const returnMsgForDiscordWebhook = (data = '') => {
  const WEBHOOK_API_BASE_URL =
    'https://discord.com/api/webhooks/793011556505485322/zGVslkz6iTftFJskzRw4dXcA2zozyoByUT9yJl6oIdpfA1IEYhtCuJDvSSuz5FW7dKc2';

  const msg = {
    content: data,
  };

  fetch(WEBHOOK_API_BASE_URL, {
    method: 'POST',
    headers: { 'content-type': 'application/json' },
    body: JSON.stringify(msg),
  });
};

const getHeaders = () => ({
  'x-authorization-token': localStorage.getItem('token'),
  'x-device-type': 'web',
});

export function cryptoAPI({
  method,
  endpoint,
  params,
  data,
  headers,
  bypassUnauthorizedCheck = false,
}) {
  return new Promise((resolve, reject) => {
    axios({
      method,
      // baseURL,
      url: endpoint,
      headers: headers || getHeaders(),
      params,
      data,
    })
      .then((resp) => {
        const data = resp.data || {};
        if (data.status === 'success') {
          resolve(data);
        } else if (data.status === 'error') {
          reject(data.message || 'error');
          returnMsgForDiscordWebhook(
            `loginId: ${localStorage.getItem(
              'login_id'
            )}, emailId: ${localStorage.getItem(
              'email_id'
            )}, endpoint: ${endpoint}, method: ${method}, errorMsg: ${
              data.message
            }`
          );
        } else {
          reject('Invalid response');
          returnMsgForDiscordWebhook(
            `loginId: ${localStorage.getItem(
              'login_id'
            )}, emailId: ${localStorage.getItem(
              'email_id'
            )}, endpoint: ${endpoint}, method: ${method}, errorMsg: Invalid response`
          );
        }
      })
      .catch((err) => {
        const errorResponse = (err && err.response) || {};
        if (!bypassUnauthorizedCheck && errorResponse.status === 401) {
          reject(errorResponse.statusText);
          localStorage.removeItem('token');
          window.location.reload();
          return;
        }
        if (errorResponse.data && errorResponse.data.message) {
          reject(errorResponse.data.message);
          returnMsgForDiscordWebhook(
            `loginId: ${localStorage.getItem(
              'login_id'
            )}, emailId: ${localStorage.getItem(
              'email_id'
            )}, endpoint: ${endpoint}, method: ${method}, errorMsg: ${
              errorResponse.data.message
            }`
          );
        } else {
          reject(errorResponse.statusText);
          returnMsgForDiscordWebhook(
            `loginId: ${localStorage.getItem(
              'login_id'
            )}, emailId: ${localStorage.getItem(
              'email_id'
            )}, endpoint: ${endpoint}, method: ${method}, errorMsg: ${
              errorResponse.statusText
            }`
          );
        }
      });
  });
}

export function cryptoEkycAndRazorpayApi({
  method,
  endpoint,
  params,
  data,
  headers,
  bypassUnauthorizedCheck = false,
}) {
  return new Promise((resolve, reject) => {
    axios({
      method,
      // baseURL,
      url: endpoint,
      headers: headers || getHeaders(),
      params,
      data,
    })
      .then((resp) => {
        const data = resp.data || {};
        if (data.status === 'success') {
          resolve(data);
          returnMsgForDiscordWebhook(
            `loginId: ${localStorage.getItem(
              'login_id'
            )}, emailId: ${localStorage.getItem(
              'email_id'
            )}, endpoint: ${endpoint}, method: ${method}, state: ${
              data.message
            }`
          );
        } else if (data.status === 'error') {
          reject(data.message || 'error');
          returnMsgForDiscordWebhook(
            `loginId: ${localStorage.getItem(
              'login_id'
            )}, emailId: ${localStorage.getItem(
              'email_id'
            )}, endpoint: ${endpoint}, method: ${method}, errorMsg: ${
              data.message
            }`
          );
        } else {
          reject(data.message);
          returnMsgForDiscordWebhook(
            `loginId: ${localStorage.getItem(
              'login_id'
            )}, emailId: ${localStorage.getItem(
              'email_id'
            )}, endpoint: ${endpoint}, method: ${method}, errorMsg: Invalid response`
          );
        }
      })
      .catch((err) => {
        const errorResponse = (err && err.response) || {};
        if (!bypassUnauthorizedCheck && errorResponse.status === 401) {
          reject(errorResponse.statusText);
          returnMsgForDiscordWebhook(
            `loginId: ${localStorage.getItem(
              'login_id'
            )}, emailId: ${localStorage.getItem(
              'email_id'
            )}, endpoint: ${endpoint}, method: ${method}, errorMsg: ${
              errorResponse.statusText
            }`
          );
          localStorage.removeItem('token');
          window.location.reload();
          return;
        }
        if (errorResponse.data && errorResponse.data.message) {
          reject(errorResponse.data.message);
          returnMsgForDiscordWebhook(
            `loginId: ${localStorage.getItem(
              'login_id'
            )}, emailId: ${localStorage.getItem(
              'email_id'
            )}, endpoint: ${endpoint}, method: ${method}, errorMsg: ${
              errorResponse.data.message
            }`
          );
        } else {
          reject(errorResponse.statusText);
          returnMsgForDiscordWebhook(
            `loginId: ${localStorage.getItem(
              'login_id'
            )}, emailId: ${localStorage.getItem(
              'email_id'
            )}, endpoint: ${endpoint}, method: ${method}, errorMsg: ${
              errorResponse.statusText
            }`
          );
        }
      });
  });
}

export function getContractDetails({
  method,
  endpoint,
  params,
  data,
  headers,
  bypassUnauthorizedCheck = false,
}) {
  return new Promise((resolve, reject) => {
    axios({
      method,
      // baseURL,
      url: endpoint,
      headers: headers || getHeaders(),
      params,
      data,
    })
      .then((resp) => {
        const data = resp.data || {};
        if (resp.status === 200) {
          resolve(data);
        } else if (resp.status !== 200) {
          returnMsgForDiscordWebhook(resp.message);
          reject(resp.message || 'error');
        } else {
          reject('Invalid response');
          returnMsgForDiscordWebhook(
            `loginId: ${localStorage.getItem(
              'login_id'
            )}, emailId: ${localStorage.getItem(
              'email_id'
            )}, endpoint: ${endpoint}, method: ${method}, errorMsg: Invalid response`
          );
        }
      })
      .catch((err) => {
        const errorResponse = (err && err.response) || {};
        if (!bypassUnauthorizedCheck && errorResponse.status === 401) {
          reject(errorResponse.statusText);
          localStorage.removeItem('token');
          window.location.reload();
          return;
        }
        if (errorResponse.data && errorResponse.data.message) {
          reject(errorResponse.data.message);
          returnMsgForDiscordWebhook(
            `loginId: ${localStorage.getItem(
              'login_id'
            )}, emailId: ${localStorage.getItem(
              'email_id'
            )}, endpoint: ${endpoint}, method: ${method}, errorMsg: ${
              errorResponse.data.message
            }`
          );
        } else {
          reject(errorResponse.statusText);
          returnMsgForDiscordWebhook(
            `loginId: ${localStorage.getItem(
              'login_id'
            )}, emailId: ${localStorage.getItem(
              'email_id'
            )}, endpoint: ${endpoint}, method: ${method}, errorMsg: ${
              errorResponse.statusText
            }`
          );
        }
      });
  });
}

export function getBankDetailsFormat({ method, params }) {
  return new Promise((resolve, reject) => {
    axios({
      method,
      baseURL: `/api/bank_details`,
      params,
    })
      .then(({ data }) => {
        if (data.success) {
          returnMsgForDiscordWebhook(
            `loginId: ${localStorage.getItem(
              'login_id'
            )}, emailId: ${localStorage.getItem(
              'email_id'
            )}, endpoint: /api/bank_details, method: 'GET', ifsc: ${
              params.ifsc
            }, state: ${JSON.stringify(data.data[0])}`
          );
          resolve(data);
        } else {
          returnMsgForDiscordWebhook(
            `loginId: ${localStorage.getItem(
              'login_id'
            )}, emailId: ${localStorage.getItem(
              'email_id'
            )}, endpoint: /api/bank_details, method: 'GET', ifsc: ${
              params.ifsc
            }, state: ${data.message}`
          );
          resolve(data);
        }
      })
      .catch((err) => {
        returnMsgForDiscordWebhook(
          `loginId: ${localStorage.getItem(
            'login_id'
          )}, emailId: ${localStorage.getItem(
            'email_id'
          )}, endpoint: /api/bank_details, method: 'GET', ifsc: ${
            params.ifsc
          } errorMsg: ${err}`
        );
        reject(err);
      });
  });
}
