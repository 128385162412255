import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Backdrop from '@material-ui/core/Backdrop';
import Lottie from 'react-lottie';
import * as loading from '../../src/9965-loading-spinner.json';

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: loading.default,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

function Loader() {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Backdrop className={classes.backdrop} open invisible>
        <Lottie options={defaultOptions} height={90} width={90} />
      </Backdrop>
    </div>
  );
}

export default Loader;
