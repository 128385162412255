import {
  cryptoAPI,
  cryptoEkycAndRazorpayApi,
  getBankDetailsFormat,
  getContractDetails,
} from './request';

// { device, g_recaptcha_response, login_id, password }
export function loginAPI(data) {
  return cryptoAPI({
    endpoint: `/api/v1/login`,
    method: 'POST',
    data,
    headers: {},
  });
}

export function loginToDisableTwoFA(data) {
  return cryptoAPI({
    endpoint: `/api/v1/login`,
    method: 'POST',
    data,
  });
}

export function checkTwoFA(data) {
  return cryptoAPI({
    endpoint: `/api/v1/checktwofa`,
    method: 'POST',
    data,
    headers: {},
  });
}

export function getTwoFACodes() {
  return cryptoAPI({
    endpoint: `/api/v1/user/twofa`,
    method: 'PUT',
    data: {
      client_id: localStorage.getItem('login_id'),
    },
  });
}

export function setTwoFACodes(twofa_code) {
  return cryptoAPI({
    endpoint: `/api/v1/user/twofa`,
    method: 'POST',
    data: {
      client_id: localStorage.getItem('login_id'),
      twofa_code,
    },
  });
}

export function removeTwoFA() {
  return cryptoAPI({
    endpoint: `/api/v1/user/twofa`,
    method: 'DELETE',
    params: {
      client_id: localStorage.getItem('login_id'),
    },
  });
}

export function fetchProfile() {
  return cryptoAPI({
    endpoint: `/app/profile`,
    method: 'GET',
  });
}

export function fetchTrades() {
  return cryptoAPI({
    endpoint: `/api/v1/trades`,
    method: 'GET',
    params: {
      client_id: localStorage.getItem('login_id'),
    },
  });
}

export function fetchCompletedOrders() {
  return cryptoAPI({
    endpoint: `/api/v1/orders`,
    method: 'GET',
    params: {
      type: 'completed',
      client_id: localStorage.getItem('login_id'),
    },
  });
}

export function fetchPending() {
  return cryptoAPI({
    endpoint: `/api/v1/orders`,
    method: 'GET',
    params: {
      type: 'pending',
      client_id: localStorage.getItem('login_id'),
    },
  });
}

export function fetchMarketWatches() {
  return cryptoAPI({
    endpoint: `/api/v1/marketwatches`,
    method: 'GET',
  });
}

export function deleteFavouriteContract({
  contractId,
  exchange,
  token,
  symbol,
}) {
  return cryptoAPI({
    endpoint: `/api/v1/marketwatches/${contractId}`,
    method: 'DELETE',
    params: {
      exchange,
      token,
      trading_symbol: symbol,
    },
  });
}

export function addFavouriteContract({
  contractId,
  exchange,
  lotSize,
  token,
  symbol,
}) {
  return cryptoAPI({
    endpoint: `/api/v1/marketwatches/${contractId}`,
    method: 'PUT',
    data: {
      exchange: String(exchange),
      lotsize: 1,
      token: String(token),
      trading_symbol: String(symbol),
    },
  });
}

export function fetchUserDetails() {
  return cryptoAPI({
    endpoint: `/api/v1/user_details`,
    method: 'GET',
    params: {
      client_id: localStorage.getItem('login_id'),
    },
  });
}

export function fetchContractDetails(exchanges) {
  return getContractDetails({
    endpoint: `/api/v1/contracts.json`,
    method: 'GET',
    params: {
      exchanges,
    },
  });
}

export function fetchFunds() {
  return cryptoAPI({
    endpoint: `/api/v1/funds`,
    method: 'GET',
    params: {
      client_id: localStorage.getItem('login_id'),
    },
  });
}

export function fetchCharts({
  exchange,
  token,
  candletype,
  starttime,
  endtime,
}) {
  return cryptoAPI({
    endpoint: `/api/v1/charts`,
    method: 'GET',
    params: {
      exchange,
      token,
      candletype,
      starttime,
      endtime,
    },
  });
}

export function fetchWalletWithdrawal() {
  return cryptoAPI({
    endpoint: `/api/v1/wallet/withdrawal`,
    method: 'GET',
    params: {
      client_id: localStorage.getItem('login_id'),
    },
  });
}

export function submitWalletWithdrawal(
  currency,
  credit_wallet_address,
  amount
) {
  return cryptoEkycAndRazorpayApi({
    endpoint: `/api/v1/wallet/withdrawal`,
    method: 'POST',
    data: {
      client_id: localStorage.getItem('login_id'),
      currency: String(currency),
      credit_wallet_address: String(credit_wallet_address),
      amount: Number(amount),
      member_id: 1002,
    },
  });
}

export function generateCoinAddress(currency) {
  return cryptoAPI({
    endpoint: `/api/v1/wallet`,
    method: 'POST',
    data: {
      client_id: localStorage.getItem('login_id'),
      currency,
      member_id: 1002,
    },
  });
}

export function fetchWalletHistory(currency_name) {
  return cryptoAPI({
    endpoint: `/api/v1/wallet/history`,
    method: 'GET',
    params: {
      client_id: localStorage.getItem('login_id'),
      currency_name,
    },
  });
}

export function fetchWithdrawalHistory() {
  return cryptoAPI({
    endpoint: `/api/v1/wallet/withdrawal`,
    method: 'GET',
    params: {
      client_id: localStorage.getItem('login_id'),
    },
  });
}

export function fetchAlerts() {
  return cryptoAPI({
    endpoint: `/api/v1/alerts`,
    method: 'GET',
  });
}

export function fetchStatus() {
  return cryptoAPI({
    endpoint: `/api/v1/brokerage_subscription/status`,
    method: 'GET',
    params: {
      client_id: localStorage.getItem('login_id'),
    },
  });
}

export function fetchKycRegions() {
  return cryptoAPI({
    endpoint: `/api/v1/regions`,
    method: 'GET',
    params: {
      client_id: localStorage.getItem('login_id'),
    },
  });
}

export function fetchKycDetails(region_id) {
  return cryptoAPI({
    endpoint: `/api/v1/kyc_details`,
    method: 'GET',
    params: {
      client_id: localStorage.getItem('login_id'),
      region_id,
    },
  });
}

export function uploadImg(file) {
  return cryptoEkycAndRazorpayApi({
    endpoint: '/api/v1/upload_image',
    method: 'POST',
    data: file,
  });
}

export function submitKyc(required_docs, region_id) {
  return cryptoEkycAndRazorpayApi({
    endpoint: '/api/v1/submit_kyc',
    method: 'POST',
    data: {
      required_docs,
      region_id,
      client_id: localStorage.getItem('login_id'),
    },
  });
}

export function updateKyc() {
  return cryptoAPI({
    endpoint: '/api/v1/kyc_update',
    method: 'PUT',
    data: {
      status: 'approve',
      client_id: localStorage.getItem('login_id'),
    },
  });
}

//payload - name, username, email_id, referral_code, device, phone_number
export function registerUser(payload) {
  return cryptoAPI({
    endpoint: `/api/v1/registration`,
    method: 'POST',
    headers: {
      'x-device-type': 'web',
    },
    data: payload,
  });
}

export function unblockUser(data) {
  return cryptoAPI({
    endpoint: `/api/v1/user/unblock`,
    method: 'POST',
    data,
    headers: {},
  });
}

export function forgotPassword(data) {
  return cryptoAPI({
    endpoint: `/api/v1/password`,
    method: 'POST',
    data,
    headers: {},
  });
}
// placeOrder -  client_id, disclosed_quantity, exchange, instrument_token, market_protection_percentage, order_side, order_type, price, product, quantity, source, trigger_price, user_order_id, validity,

export function placeOrder(payload) {
  return cryptoAPI({
    endpoint: `/api/v1/orders`,
    method: 'POST',
    data: payload,
  });
}

export function modifyOrder(payload) {
  return cryptoAPI({
    endpoint: `/api/v1/orders`,
    method: 'PUT',
    data: payload,
  });
}

export function logout() {
  return new Promise((resolve, reject) => {
    window.localStorage.clear();
    resolve({ status: true, message: 'logged out successfully' });
  });
}

export function fetchWalletDetails(currency) {
  return cryptoAPI({
    endpoint: `/api/v1/wallet/details`,
    method: 'GET',
    params: {
      client_id: localStorage.getItem('login_id'),
      currency,
    },
  });
}

export function deletePendingOrder(oms_order_id) {
  return cryptoAPI({
    endpoint: `/api/v1/orders/${oms_order_id}`,
    method: 'DELETE',
    params: {
      client_id: localStorage.getItem('login_id'),
    },
  });
}

export function deleteWalletWithdrawalReq(walletReqId) {
  return cryptoAPI({
    endpoint: `/api/v1/wallet/withdrawal`,
    method: 'DELETE',
    params: {
      wallet_request_id: walletReqId,
      client_id: localStorage.getItem('login_id'),
    },
  });
}

export function fetchTransactionHistoryAll() {
  return cryptoAPI({
    endpoint: `/api/v1/wallet/history`,
    method: 'GET',
    params: {
      client_id: localStorage.getItem('login_id'),
      currency_name: 'ALL',
    },
  });
}

export function fetchInrDepositHistory() {
  return cryptoAPI({
    endpoint: `/api/v1/inr/deposit`,
    method: 'GET',
    params: {
      client_id: localStorage.getItem('login_id'),
    },
  });
}

export function fetchContractsForLabel(currency, symbols) {
  return getContractDetails({
    endpoint: `/api/v1/ccdata/contract/all`,
    method: 'POST',
    data: {
      client_id: localStorage.getItem('login_id'),
      currency,
      symbols,
    },
  });
}

export function userBankDetails(data) {
  return cryptoAPI({
    endpoint: `/api/v1/rbac/user/bank_details`,
    method: 'POST',
    data,
  });
}

export function getBankDetails() {
  return cryptoAPI({
    endpoint: `/api/v1/rbac/user/bank_details`,
    method: 'GET',
    params: {
      client_id: localStorage.getItem('login_id'),
    },
  });
}

export function changePassword(data) {
  return cryptoAPI({
    endpoint: `/api/v1/user`,
    method: 'POST',
    data,
  });
}

export function payoutRequestSubmit(data) {
  return cryptoEkycAndRazorpayApi({
    method: 'POST',
    endpoint: '/api/v1/inr/withdrawal',
    data,
  });
}

export function inrPayoutRequest(data) {
  return cryptoEkycAndRazorpayApi({
    method: 'POST',
    endpoint: '/api/v1/pg/rzp/withdrawal',
    data,
  });
}

export function razorpayOrderCreation(data) {
  return cryptoEkycAndRazorpayApi({
    method: 'POST',
    endpoint: '/api/v1/pg/rzp/order',
    data,
  });
}

export function razorpayPaymentResponseHandler(paymentResponse) {
  let payload = {
    razorpay_order_id: paymentResponse.razorpay_order_id,
    razorpay_payment_id: paymentResponse.razorpay_payment_id,
    razorpay_signature: paymentResponse.razorpay_signature,
  };
  return cryptoEkycAndRazorpayApi({
    method: 'POST',
    endpoint: '/api/v1/pg/rzp/response',
    data: payload,
  });
}

export function changePrimaryBank(data) {
  return cryptoAPI({
    method: 'POST',
    endpoint: '/api/v2/rbac/user/bank/change_primary_bank',
    data,
  });
}

export function deleteBank(data) {
  return cryptoAPI({
    method: 'DELETE',
    endpoint: '/api/v1/rbac/user/bank/remove_bank',
    data,
  });
}

export function sendOtpToChangePrimaryBank() {
  return cryptoEkycAndRazorpayApi({
    method: 'PUT',
    endpoint: '/api/v2/rbac/user/bank/send_otp',
    data: {
      clientId: localStorage.getItem('login_id'),
    },
  });
}

export function verifyPan(data) {
  return cryptoEkycAndRazorpayApi({
    method: 'POST',
    endpoint: '/api/v1/verify_pan',
    data,
  });
}

export function getCodeToVerifyAadhar() {
  return cryptoEkycAndRazorpayApi({
    endpoint: `/api/v1/captcha`,
    method: 'GET',
    params: {
      client_id: localStorage.getItem('login_id'),
    },
  });
}

export function verifyAadhaar(data) {
  return cryptoEkycAndRazorpayApi({
    method: 'POST',
    endpoint: '/api/v1/captcha',
    data,
  });
}

export function verifyAadhaarOtp(data) {
  return cryptoEkycAndRazorpayApi({
    method: 'POST',
    endpoint: '/api/v1/get_aadhaar',
    data,
  });
}

export function generateOtpForBank() {
  return cryptoEkycAndRazorpayApi({
    method: 'POST',
    endpoint: '/api/v1/send_bank_verification_otp',
    data: {
      client_id: localStorage.getItem('login_id'),
    },
  });
}

export function verifyBank(data) {
  return cryptoEkycAndRazorpayApi({
    method: 'POST',
    endpoint: '/api/v1/verify_bank',
    data,
  });
}

export function getBankDetailsFromIfsc(ifsc) {
  return getBankDetailsFormat({
    method: 'GET',
    params: {
      ifsc,
    },
  });
}

export function getKycInfo() {
  return cryptoAPI({
    endpoint: `/api/v1/get_Kyc_info`,
    method: 'GET',
    params: {
      client_id: localStorage.getItem('login_id'),
    },
  });
}

export function offlineDeposit(data) {
  return cryptoAPI({
    endpoint: `/api/v1/inr/deposit`,
    method: 'POST',
    data,
  });
}

export function fetchCryptoHistory() {
  return cryptoAPI({
    endpoint: `/api/v1/wallet/withdrawal`,
    method: 'GET',
    params: {
      client_id: localStorage.getItem('login_id'),
    },
  });
}

export function fetchInrHistory() {
  return cryptoAPI({
    endpoint: ` /api/v1/inr/withdrawal`,
    method: 'GET',
    params: {
      client_id: localStorage.getItem('login_id'),
    },
  });
}

export function deleteCryptoWalletWithdrawalReq(walletReqId) {
  return cryptoAPI({
    endpoint: `/api/v1/wallet/withdrawal`,
    method: 'DELETE',
    params: {
      client_id: localStorage.getItem('login_id'),
      wallet_request_id: walletReqId,
    },
  });
}

export function deleteInrWalletWithdrawalReq(walletReqId) {
  return cryptoAPI({
    endpoint: `/api/v1/inr/withdrawal`,
    method: 'DELETE',
    params: {
      client_id: localStorage.getItem('login_id'),
      wallet_request_id: walletReqId,
    },
  });
}

export function deleteInrWalletDepositReq(walletReqId) {
  return cryptoAPI({
    endpoint: `/api/v1/inr/deposit`,
    method: 'DELETE',
    params: {
      client_id: localStorage.getItem('login_id'),
      wallet_request_id: walletReqId,
    },
  });
}

export function sendOcrReqForPan(path) {
  return cryptoEkycAndRazorpayApi({
    endpoint: `/api/v1/document/pan`,
    method: 'POST',
    data: {
      client_id: localStorage.getItem('login_id'),
      file_path: path,
    },
  });
}

export function sendOcrReqForAadhaar(path) {
  return cryptoEkycAndRazorpayApi({
    endpoint: `/api/v1/document/aadhaar`,
    method: 'POST',
    data: {
      client_id: localStorage.getItem('login_id'),
      file_path: path,
    },
  });
}

export function fetchCummulativePendingAmount(currency) {
  return cryptoAPI({
    endpoint: `/api/v1/cpb`,
    method: 'GET',
    params: {
      status: 'pending',
      client_id: localStorage.getItem('login_id'),
      currency: currency,
    },
  });
}
