const initialState = {
  arrPendings: [],
  arrCompleted: [],
  arrTrades: [],
  updates: {},
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case 'SET_PENDING':
      return {
        ...state,
        arrPendings: action.payload,
      };

    case 'SET_COMPLETED':
      return {
        ...state,
        arrCompleted: action.payload,
      };

    case 'SET_TRADES':
      return {
        ...state,
        arrTrades: action.payload,
      };

    case 'SET_ORDER_UPDATES':
      return {
        ...state,
        updates: action.payload,
      };
    default:
      return state;
  }
}
