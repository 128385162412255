import React, { useState, useEffect, lazy, Suspense } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { ThemeProvider } from '@material-ui/core/styles';
import './App.css';
import theme from './theme';
import { SnackbarProvider } from 'notistack';
import Button from '@material-ui/core/Button';

import Auth from './components/Auth';
import Loader from './Main/Loader';
import UnderMainteneance from './Main/UnderMaintenance';

const AsyncMain = lazy(() => import('./Main'));
// const AsyncKyc = lazy(() => import('./components/Profile/KYCVerification'));
const AsyncKyc = lazy(() => import('./components/Ekyc'));
const AsyncKycPending = lazy(() => import('./Main/PendingKyc'));

const kyc_status = {
  DRAFT: 'not_found',
  PENDING: 'pending',
  APPROVED: 'done',
  'ME-PENDING': 'done',
  REJECTED: 'not_found',
  RESUBMIT: 'not_found',
  VERIFY_AADHAAR: 'not_found',
  VERIFY_BANK: 'not_found',
  VERIFY_UPLOAD: 'not_found',
};

function App() {
  const dispatch = useDispatch();
  const snackbarRef = React.createRef();
  const onClickDismiss = (key) => () => {
    snackbarRef.current.closeSnackbar(key);
  };
  const { themeState } = useSelector((state) => state.theme);
  const [referralLink, setReferralLink] = useState(null);

  window['__react-beautiful-dnd-disable-dev-warnings'] = true;

  let regex = {
    full_Name: /^[A-Z\s,]{3,30}$/,
    user_Name: /^[A-Z0-9]{3,15}$/,
    email_id: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
    refferal_Code: /^[a-zA-Z0-9]{7,15}$/,
    account_Type: /^[A-Z]{3,15}$/,
    branch_Name: /^[a-zA-Z0-9\s,-]{3,30}$/,
    account_Number: /^\d{9,18}$/,
    bank_Name: /^[a-zA-Z\s]{3,18}$/,
    city_State: /^[a-zA-Z]{3,18}$/,
    pan_Ifsc: /^[a-zA-Z0-9]{3,18}$/,
    contact_number: /^[a-zA-Z0-9\s,]{10}$/,
    pan: /^[a-zA-Z0-9\s,]{10}$/,
    ifsc: /^[a-zA-Z0-9\s,]{11}$/,
  };

  // let depositedBanks = [
  //   {
  //     name: 'Digital Techlab Private Limited',
  //     bank_name: 'Axis Bank',
  //     branch_name: 'Fort, Mumbai - 400001',
  //     account_no: '920020066756182',
  //     ifsc: 'UTIB0000004',
  //   },
  //   {
  //     name: 'Digital Techlab Private Limited',
  //     bank_name: 'AU Bank',
  //     branch_name: 'Kurla, Mumbai - 400070',
  //     account_no: '2021234030013656',
  //     ifsc: 'AUBL0002340',
  //   },
  //   {
  //     name: 'Digital Techlab Private Limited',
  //     bank_name: 'RBL Bank',
  //     branch_name: 'Lower Parel, Mumbai - 400013',
  //     account_no: '409001376648',
  //     ifsc: 'RATN0000088',
  //   },
  // ];

  useEffect(() => {
    let url = new URL(window.location.href);
    let link = url.searchParams.get('referral_link');

    if (link !== null) {
      window.localStorage.clear();
      setReferralLink(link);
    }

    dispatch({
      type: 'GET_REGEX_FORM_VALIDATIONS',
      payload: regex,
    });
    // dispatch({
    //   type: 'SET_PAYIN_BANKS',
    //   payload: depositedBanks,
    // });
  }, []);

  function GetScreenAccordingToKycStatus(props) {
    switch (props.screenView) {
      case 'done':
      case 'ME-PENDING':
        return <AsyncMain />;

      case 'pending':
        return (
          <AsyncKycPending
            title={
              'Your kyc is submitted for verification. You can expect it to finish within 24 hours and will notified in your registered mobile and email id.'
            }
          />
        );

      case 'not_found':
        return <AsyncKyc />;

      default:
        return (
          <AsyncKycPending
            title={
              "Sorry, we're getting your kyc status as null, please do re-login or, contact to DigitX Support."
            }
          />
        );
    }
  }

  return (
    // <UnderMainteneance />
    <div>
      <ThemeProvider
        theme={theme(localStorage.getItem('token') ? themeState : 'light')}
      >
        <SnackbarProvider
          maxSnack={3}
          ref={snackbarRef}
          action={(key) => (
            <Button
              size='small'
              style={{ color: '#fff' }}
              onClick={onClickDismiss(key)}
            >
              Dismiss
            </Button>
          )}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        >
          {localStorage.getItem('token') ? (
            <Suspense fallback={<Loader />}>
              <GetScreenAccordingToKycStatus
                screenView={`${kyc_status[localStorage.getItem('kyc_status')]}`}
              />
              {/* {kyc_status[localStorage.getItem('kyc_status')] ===
                'not_found' && <AsyncKyc />}
              {kyc_status[localStorage.getItem('kyc_status')] === 'pending' && (
                <AsyncKycPending />
              )}
              {kyc_status[localStorage.getItem('kyc_status')] === 'done' && (
                <AsyncMain />
              )} */}
            </Suspense>
          ) : (
            <Auth referralLink={referralLink} />
          )}
        </SnackbarProvider>
      </ThemeProvider>
    </div>
  );
}

export default App;
