const initialState = {
  arrFunds: [],
  inrTotal: {},
  cryptoTransactionsHistory: [],
  inrTransactionsHistory: [],
  withdrawalHistory: [],
  fundObj: '',
  payinBanks: [],
  inrWithdrawalHistory: [],
  cryptoWithdrawalHistory: [],
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case 'SET_FUNDS_DATA':
      return {
        ...state,
        arrFunds: action.payload,
      };
    case 'SET_INR_FUNDS_DATA':
      return {
        ...state,
        inrTotal: action.payload,
      };
    case 'SET_CRYPTO_DEPOSITS_HISTORY':
      return {
        ...state,
        cryptoTransactionsHistory: action.payload,
      };
    case 'SET_INR_DEPOSITS_HISTORY':
      return {
        ...state,
        inrTransactionsHistory: action.payload,
      };
    case 'SET_WITHDRAWAL_HISTORY':
      return {
        ...state,
        withdrawalHistory: action.payload,
      };
    case 'SET_CRYPTO_WITHDRAWAL_HISTORY':
      return {
        ...state,
        cryptoWithdrawalHistory: action.payload,
      };
    case 'SET_INR_WITHDRAWAL_HISTORY':
      return {
        ...state,
        inrWithdrawalHistory: action.payload,
      };

    case 'SET_FUND_OBJ':
      return {
        ...state,
        fundObj: action.payload,
      };
    case 'SET_PAYIN_BANKS':
      return {
        ...state,
        payinBanks: action.payload,
      };
    default:
      return state;
  }
}
