const initialState = {
  kycRegion: {},
  getKycDetails: {},
  requiredDocs: {},
  submitKycDetails: {},
  bankDetails: [],
  kycCurrentStep: localStorage.getItem('kyc_status'),
  kycInfo: {},
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case 'SET_KYC_REGION':
      return {
        ...state,
        kycRegion: action.payload,
      };
    case 'GET_KYC_DETAILS':
      return {
        ...state,
        getKycDetails: action.payload,
      };
    case 'SET_REQ_DOCS':
      return {
        ...state,
        requiredDocs: action.payload,
      };
    case 'SET_KYC_DETAILS':
      return {
        ...state,
        submitKycDetails: action.payload,
      };
    case 'SET_BANK_DETAILS':
      return {
        ...state,
        bankDetails: action.payload,
      };
    case 'SET_KYC_INFO':
      return {
        ...state,
        kycInfo: action.payload,
      };
    default:
      return state;
  }
}
