import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Backdrop from '@material-ui/core/Backdrop';
import Lottie from 'react-lottie';
import * as loading from '../44190-under-construction-1.json';
import { Grid, Typography } from '@material-ui/core';

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: loading.default,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

const getCurrTime = () => {
  const startTime = {
    h: 18,
    m: 0,
    s: 0,
  };

  let hour = new Date().getHours() - startTime.h;
  let min = new Date().getMinutes() - startTime.m;
  let sec = new Date().getSeconds() - startTime.s;

  return {
    hour: 2 - hour,
    min: 59 - min,
    sec: 59 - sec,
  };
};

function UnderMaintenance() {
  const classes = useStyles();

  const [leftTime, setLeftTime] = useState({});

  useEffect(() => {
    const interval = setInterval(() => {
      let currTime = getCurrTime();
      setLeftTime(currTime);
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  console.log(leftTime);

  return (
    <div className={classes.root}>
      {/* <Backdrop className={classes.backdrop} open invisible> */}
      <Grid
        container
        direction='column'
        justify='space-evenly'
        alignItems='center'
      >
        <Grid item xs={12}>
          <Lottie options={defaultOptions} height={500} width={500} />
        </Grid>
        <Grid item xs={12}>
          <Typography
            variant='h2'
            gutterBottom
            style={{
              color: 'red',
            }}
          >
            It takes 3 hours to live this website again.
          </Typography>
          <Typography
            variant='h4'
            gutterBottom
            align='center'
            style={{
              color: 'green',
            }}
          >{`Back in ${leftTime.hour}:${leftTime.min}:${leftTime.sec}`}</Typography>
        </Grid>
      </Grid>
      {/* </Backdrop> */}
    </div>
  );
}

export default UnderMaintenance;
