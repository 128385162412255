import { useState } from 'react';

const useForm = () => {
  const [state, setState] = useState({});

  const handleChangeValue = (e, isCapsOn, toggleBar = false) => {
    e.persist();
    if (isCapsOn) {
      setState((state) => ({
        ...state,
        [e.target.name]: e.target.value.toUpperCase(),
      }));
    } else if (toggleBar) {
      setState((state) => ({ ...state, [e.target.name]: e.target.checked }));
    } else {
      setState((state) => ({ ...state, [e.target.name]: e.target.value }));
    }
  };

  const handleChangeForTheFirstMount = (name, value) => {
    setState((state) => ({ ...state, [name]: value }));
  };

  return [state, handleChangeValue, handleChangeForTheFirstMount];
};

export default useForm;
