const initialState = {
  themeState: localStorage.getItem('theme'),
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case 'SET_LIGHT_THEME':
      return {
        ...state,
        themeState: localStorage.getItem('theme'),
      };
    case 'SET_DARK_THEME':
      return {
        ...state,
        themeState: localStorage.getItem('theme'),
      };
    default:
      return state;
  }
}
