const initialState = {
  pendingDetailModal: {
    isOpen: false,
    payload: {},
  },
  orderDetailModal: {
    isOpen: false,
    payload: {},
  },
  tradeDetailModal: {
    isOpen: false,
    payload: {},
  },
  cancelPendingModal: {
    isOpen: false,
    payload: {},
  },
  cancelWalletWithdrawalReq: {
    isOpen: false,
    payload: {},
  },
  buySellModal: {
    isOpen: false,
    payload: {},
  },
  fundsDepositWithdrawModal: {
    isOpen: false,
    payload: {},
  },
  qrCodeModal: {
    isOpen: false,
    payload: {},
  },
  disableQrCodeModal: {
    isOpen: false,
    payload: {},
  },
  viewAddressModal: {
    isOpen: false,
    payload: {},
  },
  transactionHistoryModal: {
    isOpen: false,
    payload: {},
  },
  withdrawalHistoryModal: {
    isOpen: false,
    payload: {},
  },
  depositHistoryModal: {
    isOpen: false,
    payload: {},
  },
  inrDepositWithdrawalModal: {
    isOpen: false,
    payload: {},
  },
  // inrDepositModal: {
  //   isOpen: false,
  //   payload: {},
  // },
  chartModal: {
    isOpen: false,
    payload: {},
  },
  orderBookModal: {
    isOpen: false,
    payload: {},
  },
  tradeHistoryModal: {
    isOpen: false,
    payload: {},
  },
  payoutModal: {
    isOpen: false,
    payload: {},
  },
  otpModal: {
    isOpen: false,
    payload: {},
  },
  panModal: {
    isOpen: false,
    payload: {},
  },
  aadhaarModal: {
    isOpen: false,
    payload: {},
  },
  bankModal: {
    isOpen: false,
    payload: {},
  },
  uploadModal: {
    isOpen: false,
    payload: {},
  },
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case 'SHOW_PENDING_DETAIL_MODAL':
      return {
        ...state,
        pendingDetailModal: {
          isOpen: true,
          payload: action.payload,
        },
      };
    case 'HIDE_PENDING_DETAIL_MODAL':
      return {
        ...state,
        pendingDetailModal: {
          isOpen: false,
          payload: action.payload,
        },
      };
    case 'SHOW_ORDER_DETAIL_MODAL':
      return {
        ...state,
        orderDetailModal: {
          isOpen: true,
          payload: action.payload,
        },
      };
    case 'HIDE_ORDER_DETAIL_MODAL':
      return {
        ...state,
        orderDetailModal: {
          isOpen: false,
          payload: action.payload,
        },
      };
    case 'SHOW_TRADE_DETAIL_MODAL':
      return {
        ...state,
        tradeDetailModal: {
          isOpen: true,
          payload: action.payload,
        },
      };
    case 'HIDE_TRADE_DETAIL_MODAL':
      return {
        ...state,
        tradeDetailModal: {
          isOpen: false,
          payload: action.payload,
        },
      };
    case 'SHOW_PENDING_CANCEL_MODAL':
      return {
        ...state,
        cancelPendingModal: {
          isOpen: true,
          payload: action.payload,
        },
      };
    case 'HIDE_PENDING_CANCEL_MODAL':
      return {
        ...state,
        cancelPendingModal: {
          isOpen: false,
          payload: action.payload,
        },
      };
    case 'SHOW_WALLET_WITHDRAWAL_HISTORY_MODAL':
      return {
        ...state,
        cancelWalletWithdrawalReq: {
          isOpen: true,
          payload: action.payload,
        },
      };
    case 'HIDE_WALLET_WITHDRAWAL_HISTORY_MODAL':
      return {
        ...state,
        cancelWalletWithdrawalReq: {
          isOpen: false,
          payload: action.payload,
        },
      };
    case 'SHOW_BUY_SELL_MODAL':
      return {
        ...state,
        buySellModal: {
          isOpen: true,
          payload: action.payload,
        },
      };
    case 'HIDE_BUY_SELL_MODAL':
      return {
        ...state,
        buySellModal: {
          isOpen: false,
          payload: action.payload,
        },
      };
    case 'SHOW_FUNDS_DEPOSIT_WITHDRAW_MODAL':
      return {
        ...state,
        fundsDepositWithdrawModal: {
          isOpen: true,
          payload: action.payload,
        },
      };
    case 'HIDE_FUNDS_DEPOSIT_WITHDRAW_MODAL':
      return {
        ...state,
        fundsDepositWithdrawModal: {
          isOpen: false,
          payload: action.payload,
        },
      };
    case 'SHOW_QR_CODE_MODAL':
      return {
        ...state,
        qrCodeModal: {
          isOpen: true,
          payload: action.payload,
        },
      };
    case 'HIDE_QR_CODE_MODAL':
      return {
        ...state,
        qrCodeModal: {
          isOpen: false,
          payload: action.payload,
        },
      };
    case 'SHOW_DISABLE_QR_CODE_MODAL':
      return {
        ...state,
        disableQrCodeModal: {
          isOpen: true,
          payload: action.payload,
        },
      };
    case 'HIDE_DISABLE_QR_CODE_MODAL':
      return {
        ...state,
        disableQrCodeModal: {
          isOpen: false,
          payload: action.payload,
        },
      };
    case 'SHOW_GENERATED_ADDRESS_MODAL':
      return {
        ...state,
        viewAddressModal: {
          isOpen: true,
          payload: action.payload,
        },
      };
    case 'HIDE_GENERATED_ADDRESS_MODAL':
      return {
        ...state,
        viewAddressModal: {
          isOpen: false,
          payload: action.payload,
        },
      };
    case 'SHOW_TRANSACTION_HISTORY_MODAL':
      return {
        ...state,
        transactionHistoryModal: {
          isOpen: true,
          payload: action.payload,
        },
      };
    case 'HIDE_TRANSACTION_HISTORY_MODAL':
      return {
        ...state,
        transactionHistoryModal: {
          isOpen: false,
          payload: {},
        },
      };
    case 'SHOW_WITHDRAWAL_HISTORY_MODAL':
      return {
        ...state,
        withdrawalHistoryModal: {
          isOpen: true,
          payload: action.payload,
        },
      };
    case 'HIDE_WITHDRAWAL_HISTORY_MODAL':
      return {
        ...state,
        withdrawalHistoryModal: {
          isOpen: false,
          payload: {},
        },
      };
      case 'SHOW_DEPOSIT_HISTORY_MODAL':
        return {
          ...state,
          depositHistoryModal: {
            isOpen: true,
            payload: action.payload,
          },
        };
      case 'HIDE_DEPOSIT_HISTORY_MODAL':
        return {
          ...state,
          depositHistoryModal: {
            isOpen: false,
            payload: {},
          },
        };
    case 'SHOW_INR_DEPOSIT_WITHDRAWAL_MODAL':
      return {
        ...state,
        inrDepositWithdrawalModal: {
          isOpen: true,
          payload: action.payload,
        },
      };
    case 'HIDE_INR_DEPOSIT_WITHDRAWAL_MODAL':
      return {
        ...state,
        inrDepositWithdrawalModal: {
          isOpen: false,
          payload: {},
        },
      };
    // case 'SHOW_INR_DEPOSIT_MODAL':
    //   return {
    //     ...state,
    //     inrDepositModal: {
    //       isOpen: true,
    //       payload: action.payload,
    //     },
    //   };
    // case 'HIDE_INR_DEPOSIT_MODAL':
    //   return {
    //     ...state,
    //     inrDepositModal: {
    //       isOpen: false,
    //       payload: {},
    //     },
    //   };
    case 'SHOW_CHART_MODAL':
      return {
        ...state,
        chartModal: {
          isOpen: true,
          payload: action.payload,
        },
      };
    case 'HIDE_CHART_MODAL':
      return {
        ...state,
        chartModal: {
          isOpen: false,
          payload: {},
        },
      };
    case 'SHOW_ORDER_BOOK_MODAL':
      return {
        ...state,
        orderBookModal: {
          isOpen: true,
          payload: action.payload,
        },
      };
    case 'HIDE_ORDER_BOOK_MODAL':
      return {
        ...state,
        orderBookModal: {
          isOpen: false,
          payload: {},
        },
      };
    case 'SHOW_TRADE_HISTORY_MODAL':
      return {
        ...state,
        tradeHistoryModal: {
          isOpen: true,
          payload: action.payload,
        },
      };
    case 'HIDE_TRADE_HISTORY_MODAL':
      return {
        ...state,
        tradeHistoryModal: {
          isOpen: false,
          payload: {},
        },
      };
    case 'SHOW_PAYOUT_RESPONSE_MODAL':
      return {
        ...state,
        payoutModal: {
          isOpen: true,
          payload: action.payload,
        },
      };
    case 'HIDE_PAYOUT_RESPONSE_MODAL':
      return {
        ...state,
        payoutModal: {
          isOpen: false,
          payload: {},
        },
      };
    case 'SHOW_OTP_MODAL':
      return {
        ...state,
        otpModal: {
          isOpen: true,
          payload: action.payload,
        },
      };
    case 'HIDE_OTP_MODAL':
      return {
        ...state,
        otpModal: {
          isOpen: false,
          payload: {},
        },
      };
    case 'SHOW_PAN_MODAL':
      return {
        ...state,
        panModal: {
          isOpen: true,
          payload: action.payload,
        },
      };
    case 'HIDE_PAN_MODAL':
      return {
        ...state,
        panModal: {
          isOpen: false,
          payload: {},
        },
      };

    case 'SHOW_AADHAAR_MODAL':
      return {
        ...state,
        aadhaarModal: {
          isOpen: true,
          payload: action.payload,
        },
      };
    case 'HIDE_AADHAAR_MODAL':
      return {
        ...state,
        aadhaarModal: {
          isOpen: false,
          payload: {},
        },
      };

    case 'SHOW_BANK_MODAL':
      return {
        ...state,
        bankModal: {
          isOpen: true,
          payload: action.payload,
        },
      };
    case 'HIDE_BANK_MODAL':
      return {
        ...state,
        bankModal: {
          isOpen: false,
          payload: {},
        },
      };

    case 'SHOW_UPLOAD_MODAL':
      return {
        ...state,
        uploadModal: {
          isOpen: true,
          payload: action.payload,
        },
      };
    case 'HIDE_UPLOAD_MODAL':
      return {
        ...state,
        uploadModal: {
          isOpen: false,
          payload: {},
        },
      };
    default:
      return state;
  }
}
