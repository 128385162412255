import React from 'react';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { unblockUser } from '../../services/backendAPI';
import { useSnackbar } from 'notistack';

import Form from '../Common/Forms';
import useForm from '../../hooks/useForm';

const useStyles = makeStyles((theme) => ({
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  paper: {
    margin: theme.spacing(7, 5),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    textTransform: 'capitalize',
  },
}));

function UnblockUser({ setCurrentStep }) {
  const [values, handleChangeValue, handleChangeForTheFirstMount] = useForm();

  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const forgot = (valuesObj) => {
    unblockUser(valuesObj)
      .then(({ message, status }) => {
        if (status === 'success') {
          enqueueSnackbar(message, { variant: 'success' });
          setCurrentStep('LOGIN');
        }
      })
      .catch((err) => enqueueSnackbar(err, { variant: 'error' }));
  };

  return (
    <>
      <Typography component='h1' variant='h5'>
        Unblock User
      </Typography>
      <form className={classes.form} noValidate>
        <br />
        <br />

        <Form
          variant='standard'
          classes={classes}
          label='Username'
          name='client_id'
          value={values.client_id || ''}
          handleChangeValue={(e) => handleChangeValue(e, true)}
        />
        <br />
        <Form
          variant='standard'
          classes={classes}
          label='Pan'
          name='pan'
          value={values.pan || ''}
          handleChangeValue={(e) => handleChangeValue(e, true)}
        />
        <br />
        <br />
        <Button
          type='submit'
          fullWidth
          size='large'
          variant='contained'
          color='primary'
          className={classes.submit}
          onClick={(e) => {
            e.preventDefault();
            forgot(values);
          }}
        >
          Reset
        </Button>
        <Button
          size='large'
          fullWidth
          color='primary'
          className='text-capitalize'
          onClick={() => setCurrentStep('LOGIN')}
        >
          Back to Login
        </Button>
      </form>
    </>
  );
}

export default UnblockUser;
