const initialState = {
  form: {},
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case 'GET_REGEX_FORM_VALIDATIONS':
      return {
        ...state,
        form: action.payload,
      };
    default:
      return state;
  }
}
