import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { useSnackbar } from 'notistack';
import { loginAPI, checkTwoFA, logout } from '../../services/backendAPI';
import Backdrop from '@material-ui/core/Backdrop';
import Loader from '../../Main/Loader';
import { getKycInfoAction } from '../../store/actions/kyc';

import Form from '../Common/Forms';
import useForm from '../../hooks/useForm';

const useStyles = makeStyles((theme) => ({
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  paper: {
    margin: theme.spacing(7, 5),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  typo: {
    textDecoration: 'underline',
    color: 'blue',
    cursor: 'pointer',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    textTransform: 'capitalize',
  },
}));

function Authenticator({ setCurrentStep, title, label }) {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const { loginCredentials } = useSelector((state) => state.loginCred);

  const [values, handleChangeValue, handleChangeForTheFirstMount] = useForm();
  const [counter, setCounter] = useState(30);
  const [loading, setLoading] = useState(false);
  const classes = useStyles();

  const setAuthenticatorCode = (valuesObj) => {
    setLoading(true);

    let data = valuesObj;
    data['login_id'] = localStorage.getItem('login_id');
    data['twofa_token'] = localStorage.getItem('twofa_token');

    checkTwoFA(data)
      .then(({ status, data }) => {
        setLoading(false);
        if (status === 'success') {
          if (data.kycStatus === null) {
            logout()
              .then((res) => {
                enqueueSnackbar("kyc status can't be null", {
                  variant: 'warning',
                });
                window.location.reload();
              })
              .catch((err) =>
                enqueueSnackbar('logout failed !', { variant: 'error' })
              );
          } else {
            localStorage.setItem('token', data.auth_token);
            // dispatch(getKycInfoAction());
            window.location.reload();
          }
        }
      })
      .catch((err) => {
        setLoading(false);
        enqueueSnackbar(err, { variant: 'error' });
      });
  };

  useEffect(() => {
    counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);
  }, [counter]);

  const login = (loginReqPayload) => {
    setLoading(true);

    loginAPI(loginReqPayload)
      .then(({ data, message }) => {
        localStorage.setItem('twofa_token', data.twofa_token);
        localStorage.setItem('twofa_enabled', data.twofa_enabled);
        localStorage.setItem('kyc_status', data.kycStatus);
        localStorage.setItem('kyc_submitted', data.kycSubmitted);
        setLoading(false);
        enqueueSnackbar(
          'otp sent to your registered mobile number and email id',
          {
            variant: 'success',
          }
        );
        setCounter(30);
      })
      .catch((err) => {
        enqueueSnackbar(err, { variant: 'error' });
        setLoading(false);
        localStorage.clear();
      });
  };

  return (
    <>
      <Backdrop className={classes.backdrop} open={loading}>
        <Loader />
      </Backdrop>
      <Typography component='h1' variant='h5'>
        {title}
      </Typography>
      <form
        className={classes.form}
        noValidate
        onSubmit={(e) => {
          e.preventDefault();
          setAuthenticatorCode(values);
        }}
      >
        <br />
        <br />
        <Form
          variant='standard'
          classes={classes}
          label={label}
          name='twofa_code'
          value={values.twofa_code || ''}
          handleChangeValue={(e) => handleChangeValue(e, false)}
        />
        <br />
        <br />
        <Button
          type='submit'
          fullWidth
          size='large'
          variant='contained'
          color='primary'
          className={classes.submit}
          onClick={(e) => {
            e.preventDefault();
            setAuthenticatorCode(values);
          }}
        >
          Submit
        </Button>
        {counter === 0 ? (
          <div>
            <Typography variant='body2' display='inline'>
              Didn't receive otp
            </Typography>{' '}
            <Typography
              variant='body2'
              display='inline'
              classes={{ root: classes.typo }}
              onClick={() => login(loginCredentials)}
            >
              Resend
            </Typography>
          </div>
        ) : (
          <Typography variant='body2'>{`Resend otp in ${counter}`}</Typography>
        )}
      </form>
    </>
  );
}

export default Authenticator;
