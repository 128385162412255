const initialState = {
  orderUpdates: [],
  unseenOrderUpdates: 0,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case 'CLEAR_ORDER_UPDATE':
      return {
        ...state,
        orderUpdates: [],
      };
    case 'PREPEND_ORDER_UPDATE':
      return {
        ...state,
        orderUpdates: [action.payload, ...state.orderUpdates],
        unseenOrderUpdates: state.unseenOrderUpdates + 1,
      };
    case 'SET_UNSEEN_ORDER_UPDATES':
      return {
        ...state,
        unseenOrderUpdates: action.payload,
      };
    default:
      return state;
  }
}
