import { createMuiTheme } from '@material-ui/core/styles';
export default function theme(themeState) {
  return createMuiTheme({
    palette: {
      common: {
        black: '#000',
        white: '#fff',
      },
      type: themeState,
      primary: {
        main: '#1c6dff',
      },
      background: {
        paper: themeState === 'dark' ? '#0c1630' : '#fff',
        default: themeState === 'dark' ? '#242d43' : '#fff',
      },
      contrastThreshold: 3,
      tonalOffset: 1,
    },
    typography: {
      fontFamily: 'Ubuntu',
      tab: {
        textTransform: 'none',
        fontWeight: 700,
        fontSize: '1rem',
        color: 'black',
        fontFamily: 'Exo 2',
      },
    },
  });
}
