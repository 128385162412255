import React, { useState, useEffect } from 'react';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';
import { registerUser } from '../../services/backendAPI';
import { useSnackbar } from 'notistack';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import useForm from '../../hooks/useForm';

import Form from '../Common/Forms';

const useStyles = makeStyles((theme) => ({
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  paper: {
    margin: theme.spacing(7, 5),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    textTransform: 'capitalize',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

function RegistrationView({ setCurrentStep, referralLink }) {
  const [values, handleChangeValue, handleChangeForTheFirstMount] = useForm();

  const classes = useStyles();
  const [terms, setTerms] = useState(false);
  const [privacy, setPrivacy] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isReferralFieldDisabled, setIsReferralFieldDisabled] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const register = (registerQuery) => {
    if (terms && privacy) {
      setLoading(true);

      registerQuery['device'] = 'web';
      registerQuery['username'] = '';

      registerUser(registerQuery)
        .then(({ data, message }) => {
          enqueueSnackbar(`${message} please check your email id.`, {
            variant: 'success',
          });
          setCurrentStep('LOGIN');
          setLoading(false);
        })
        .catch((err) => {
          enqueueSnackbar(err, { variant: 'error' });
          setLoading(false);
        });
    } else {
      enqueueSnackbar('Please accept terms of service and provacy policy', {
        variant: 'warning',
      });
    }
  };

  useEffect(() => {
    if (referralLink != null) {
      handleChangeForTheFirstMount('referral_code', referralLink);
      setIsReferralFieldDisabled(true);
    }
  }, [referralLink]);

  return (
    <>
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color='inherit' />
      </Backdrop>
      <Typography component='h1' variant='h5'>
        Sign Up
      </Typography>
      <br />
      <Form
        variant='standard'
        classes={classes}
        type='text'
        label='Full Name'
        name='name'
        value={values.name || ''}
        handleChangeValue={(e) => handleChangeValue(e, false)}
      />
      <br />
      <Form
        variant='standard'
        classes={classes}
        type='text'
        label='Email Id'
        name='email_id'
        value={values.email_id || ''}
        handleChangeValue={(e) => handleChangeValue(e, false)}
      />
      <br />
      <Form
        variant='standard'
        classes={classes}
        type='number'
        label='Mobile Number'
        name='phone_number'
        value={values.phone_number || ''}
        handleChangeValue={(e) => handleChangeValue(e, false)}
      />
      <br />
      <Form
        isDisabled={isReferralFieldDisabled}
        variant='standard'
        classes={classes}
        type='text'
        label='Referral Code'
        name='referral_code'
        value={values.referral_code || ''}
        handleChangeValue={(e) => handleChangeValue(e, false)}
      />
      <br />
      <Box display='grid'>
        <FormControlLabel
          control={
            <Checkbox
              value={terms}
              onChange={() => setTerms(!terms)}
              color='primary'
            />
          }
          label={
            <>
              I Agree the{' '}
              <Link href='#' color='primary'>
                Terms & Conditions
              </Link>
            </>
          }
        />
        <FormControlLabel
          control={
            <Checkbox
              value={privacy}
              onChange={() => setPrivacy(!privacy)}
              color='primary'
            />
          }
          label={
            <>
              I Agree the{' '}
              <Link href='#' color='primary'>
                Privacy Policy
              </Link>
            </>
          }
        />
      </Box>
      <br />
      <Grid container spacing={2}>
        <Grid item xs={12} md={6} align='left'>
          <Button
            type='submit'
            fullWidth
            size='large'
            variant='contained'
            color='primary'
            className={`select-text ${classes.submit}`}
            onClick={() => register(values)}
          >
            Sign up
          </Button>
        </Grid>
        <Grid item xs={12} md={6} align='right'>
          <Button
            type='submit'
            fullWidth
            size='large'
            variant='contained'
            color='primary'
            className={`select-text ${classes.submit}`}
            onClick={() => setCurrentStep('LOGIN')}
          >
            Back to Sign In
          </Button>
        </Grid>
      </Grid>
    </>
  );
}

export default RegistrationView;
