const initialState = {
  userDetails: [],
  aadharDetails: {},
  userStatus: 'APPROVED',
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case 'SET_USER_DETAILS':
      return {
        ...state,
        userDetails: action.payload,
      };
    case 'SET_AADHAAR_DETAILS':
      return {
        ...state,
        aadharDetails: action.payload,
      };
    case 'SET_KYC_STATUS':
      return {
        ...state,
        userStatus: action.payload,
      };
    default:
      return state;
  }
}
